// 大数据辅助决策
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 一键生成报告--区域查询
export function getAreaByLift(params) {
  return http({
    url: `${preApi}/area/getAreaByLift`,
    method: "post",
    data: params
  })
}

// 一键生成报告
export function exportReportWordStatistic(params) {
  params.filename = '工作报告.docx';
  return http({
    url: `${preApi}/bigdata/exportReportWordStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

export function exportReportWordStatisticToBackground(params) {
  params.filename = '工作报告.docx';
  return http({
    url: `${preApi}/bigdata/exportReportWordStatisticToBackground`,
    method: "post",
    data: params,
  })
}


// 获取温度数据
export function getTemperatureByAreaname(params) {
  return http({
    url: `${preApi}/weather/getTemperatureByAreaname`,
    method: "post",
    data: params,
  })
}
// 获取电梯运行数据
export function getStatusDataCountListByConditon(params) {
  return http({
    url: `${preApi}/lift/getStatusDataCountListByConditon`,
    method: "post",
    data: params,
  })
}